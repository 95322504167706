html, body, .full-height {
  height: 100%;
}

.form-container {
  padding: 0 2rem;
  border-right: 1px dashed;
}

.preview-container {
	overflow-x: auto;
}