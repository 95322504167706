.signature-form {
	padding: 1rem;

	&__iconic-logo {
		height: 2rem;
		margin-bottom: 1rem;
	}

	.form-group {
		width: 100%;
	}
}